import React, { ReactElement } from 'react';
import Slider from 'react-slick';
import { graphql, useStaticQuery } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';
import { CarouselHeroesQuery, ImageSharp } from '@/../graphql-types';

import 'slick-carousel/slick/slick.css';
import './slick-theme.css';
import {
  heroImg,
  heroImgWrapper,
  zoomingHeroImg,
} from '@/styles/hero.module.css';

const carouselImageQuery = graphql`
  query CarouselHeroes {
    desktop1: file(relativePath: { eq: "hero-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    desktop2: file(relativePath: { eq: "hero-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    desktop3: file(relativePath: { eq: "hero-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`;

type ImageData = { childImageSharp?: Pick<ImageSharp, 'gatsbyImageData'> };

const CarouselSlide = ({ desktop }: { desktop: ImageData }): ReactElement => (
  <GatsbyImage
    image={desktop.childImageSharp.gatsbyImageData}
    alt="Boce ulja"
    loading="eager"
    className={heroImgWrapper}
    imgClassName={`${heroImg} ${zoomingHeroImg}`}
  />
);

const Carousel = (): ReactElement => {
  const images = useStaticQuery<CarouselHeroesQuery>(carouselImageQuery);

  return (
    <Slider
      arrows
      fade
      autoplay
      autoplaySpeed={7500}
      speed={1500}
      pauseOnHover={false}
      className="h-full relative"
      prevArrow={<ArrowLeft />}
      nextArrow={<ArrowRight />}
    >
      <CarouselSlide desktop={images.desktop1} />
      <CarouselSlide desktop={images.desktop2} />
      <CarouselSlide desktop={images.desktop3} />
    </Slider>
  );
};

export default Carousel;
