import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import { StaticImage } from 'gatsby-plugin-image';
import BgImage from '@/components/BgImage';

import { ReactComponent as EkoLogos } from '../assets/eko-logos.svg';

import { bgTearLeft, bgTearTop } from '@/styles/page.module.css';

import { ReactComponent as LogoVertical } from '../assets/logo-full-vertical.svg';
import NavBar from '@/components/NavBar';
import Footer from '@/components/Footer';
import Head from '@/components/Head';
import Carousel from '@/components/Carousel';

const Home: React.FC<PageProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head />

      <NavBar />
      <main className="h-9/16 md:h-screen" style={{ paddingTop: '60px' }}>
        <div className="w-full h-full d-flex relative">
          {/* Fix for borked lighthouse tooling expecting content and failing to give score */}
          <div className="absolute left-0 top-0" aria-hidden>
            Kota
          </div>
          <Carousel />
        </div>
      </main>

      <div className="relative">
        <BgImage />
        <section className="flex items-center flex-col py-36 pb-52 px-8 md:px-16">
          <h1 className="title-text text-center">{t('index.welcome')}</h1>
          <hr className="text-gold-default w-1/2 my-36" />
          <div className="w-full max-w-screen-md md:w-1/3">
            <EkoLogos className="w-100 text-gold-default" />
          </div>
        </section>

        <section
          className={`flex flex-wrap items-center w-full xl:w-11/12 py-48 ${bgTearLeft}`}
        >
          <div className="w-full md:w-1/2 xl:w-2/3 px-8 md:pl-28 mb-12 md:mb-0">
            <div className="xl:w-3/4">
              <h1 className="title-text mb-10">{t('index.our_story_title')}</h1>
              <p className="body-text text-gray-darker">
                {t('index.our_story_text')}
              </p>
              <Link to="/about" className="btn mt-5">
                {t('index.more_about_us')}
              </Link>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 px-16 xl:px-2">
            <div className="relative">
              <div className="bg-gold-default absolute w-full md:w-3/4 -right-4 -top-4 -bottom-4" />
              <StaticImage
                src="../assets/nasa-prica.jpg"
                alt="Slike boca ulja"
                className="relative z-10"
                layout="fullWidth"
                sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 100vw"
              />
            </div>
          </div>
        </section>

        <section className="py-32 flex">
          <div className="flex flex-wrap items-center max-w-screen-xl px-2 mx-auto w-full justify-center text-center md:text-left">
            <div className="md:w-1/2 text-gold-default px-16 flex mb-16 md:mb-0">
              <LogoVertical className="md:w-1/2 mx-auto" />
            </div>
            <div className="md:w-1/2 px-8">
              <h1 className="title-text mb-10">
                {t('index.brand_story_title')}
              </h1>

              <p className="body-text text-gray-light mb-8">
                {t('index.brand_story_text')}
              </p>
            </div>
          </div>
        </section>

        <section className="flex w-full flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-1/3 relative">
            <StaticImage
              src="../assets/nasa-vizija-pic.png"
              layout="fullWidth"
              alt="Boce ulja"
              sizes="(min-width: 1280px) 33vw, (min-width: 1024px) 50vw, 100vw"
            />
          </div>
          <div
            className={`w-full lg:w-1/2 xl:w-2/3 flex relative ${bgTearTop}`}
          >
            <div className="flex flex-col justify-center mx-auto max-w-screen-md px-16 py-32">
              <h1 className="title-text mb-10">
                {t('index.our_vision_title')}
              </h1>
              <p className="body-text text-gray-darker">
                {t('index.our_vision_text')}
              </p>
              <h1 className="title-text mb-10 mt-28">
                {t('index.our_mission_title')}
              </h1>
              <p className="body-text text-gray-darker">
                {t('index.our_mission_text')}
              </p>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
